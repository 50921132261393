import styles from "./Profile.module.css";
import { BiEdit } from "react-icons/bi";
import profile from "../../assets/Images/profile icon.png";
import { AiFillCamera } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/Styles/Common.css";
import { useEffect, useState } from "react";
import { Toast } from "../../utils/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { customerDetail, updateDetails } from "../../store/slices/UserSlice";
import { userLogoutClear } from "../../store/slices/UserSlice";
import { STATUS_CODES } from "../../utils/StatusCode";

// --------function for profile image update--------
function UserProfile() {
  const { t } = useTranslation();
  const { userToken,updateUser } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profilePreview, setProfilePreview] = useState(updateUser ? (updateUser?.customer_profile_image?updateUser?.customer_profile_image:profile) : profile);
  // const [customerData, setCustomerData] = useState("");
  const [imageError, setImageError] = useState("")
  console.log('profilePreview',profilePreview)
  // --------function for get profile details api call--------
  useEffect(() => {
    dispatch(customerDetail(userToken)).then((responsejson) => {
      const response = responsejson.payload;
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        // setCustomerData(response.data[0]);
        const data = response && response.data[0];
        console.log('response.customer_profile_image',data.customer_profile_image)
        setProfilePreview( data ? data.customer_profile_image : profilePreview
        );
      } else if (response && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: t("SESSION_EXPIRE"),
        });
        dispatch(userLogoutClear());
        navigate("/login");
      }
    });
  }, []);

  //----- function for Upload update profile image-----
  function onImageChange(e) {
    const image = e.target.files[0];
    if (image && !image.name.match(/\.(jpg|jpeg|png|gif|webp|avif|apng)$/)) {
      setImageError("Select valid image.");
      Toast.fire({
        icon: "error",
        title: "Please Select only image. ",
      });
      return false;
    }
        if (e.target.files.length !== 0) {
          let requestData = new FormData();
          requestData.append("user_name", updateUser && updateUser.customer_user_name);
          requestData.append("email",  updateUser && updateUser.customer_email);
          requestData.append("profile_pic", e.target.files[0]);

          const data = { requestData: requestData, userToken: userToken };
          dispatch(updateDetails(data)).then((responsejson) => {
            setImageError("");
            const response = responsejson.payload;
            const data = response && response.settings.data[0];
            if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
              if(data.customer_profile_image){
              setProfilePreview(data.customer_profile_image);
            }
              Toast.fire({
                icon: "success",
                title: "profile photo updated successfully",
              });
              navigate("/profile");
            } else if (response && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
              Toast.fire({
                icon: "error",
                title: t("SESSION_EXPIRE"),
              });
              dispatch(userLogoutClear());
              navigate("/login");
            } else {
              Toast.fire({
                icon: "error",
                title: response && response.settings.message,
              });
            }
          });
        }
      }

  //----- function for manage user logout-----
  const logout = async () => {
    dispatch(userLogoutClear());
    navigate("/");
  };

  return (
    <div>
      <div className={styles.profileInfo}>
        {location.pathname == "/edit-profile" ? (
          ""
        ) : (
          <div className={styles.editIcon}>
            <BiEdit onClick={() => navigate("/edit-profile")} />
          </div>
        )}
        <div className={styles.profileImg}>
          <img src={profilePreview} alt="profile" />
        </div>
        <div className={styles.profileInput}>
          <input
            accept="image/*"
            className="inputfile hideDiv"
            id="file-1"
            name="profileImage"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              onImageChange(e);
            }}
          />
          <label htmlFor="file-1" className={styles.uploadPic}>
            <AiFillCamera />
          </label>
          
        </div>
        {/* {imageError &&<p> <span className="errorMsg">{imageError}</span></p>} */}
        <div className={styles.nameHeding}>
          <h2>
            { updateUser && updateUser.customer_full_name &&
               updateUser.customer_full_name.charAt(0).toUpperCase() +
               updateUser.customer_full_name.slice(1).toLowerCase()}
          </h2>
          { updateUser && updateUser.customer_user_name && (
            <h4 className={styles.userName}>
              @{updateUser.customer_user_name}
            </h4>
          )}
        </div>

        <div className={styles.logoutBtn}>
          <button onClick={logout}>{t("LOGOUT_BTN")}</button>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
