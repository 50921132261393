import { Container, Navbar } from "react-bootstrap";
import styles from "../HeaderSection/HeaderSection.module.css";
import { NavLink } from "react-router-dom";
import logo from "../../assets/Images/ratinglogo.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { salesVisibility } from "../../store/slices/UserSlice";
import { useEffect } from "react";

// -------------function for header section-----------
function HeaderSection() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currentUser, salePlanVisible, updateUser } = useSelector(
    (state) => state.user
  );

  // -------------function for salevisibility api-----------
  useEffect(() => {
    dispatch(salesVisibility()).then((responsejson) => {
      const response = responsejson.payload;
    });
  }, []);

  return (
    <section className={styles.headerSection}>
      <Container className={styles.headerContent}>
        <div className={styles.navBar}>
          <Navbar expand="md">
            <Navbar.Brand>
              <NavLink to="/">
                {Object.keys(currentUser).length == 0 ? (
                  <div className={styles.logoImg}>
                    <img src={logo} alt="logo" />
                  </div>
                ) : (
                  updateUser.customer_profile_image && (
                    <div className={styles.logoImgs}>
                      <img src={updateUser.customer_profile_image} alt="logo" />
                    </div>
                  )
                )}
              </NavLink>
            </Navbar.Brand>
          </Navbar>
          <div className="activeShow">
            {salePlanVisible && salePlanVisible.value == "1" ? (
              <NavLink className={styles.customNavLink} to="/exclusive">
                {t("SALE")}
              </NavLink>
            ) : (
              ""
            )}

            {Object.keys(currentUser).length !== 0 ? (
              currentUser && currentUser.is_subscribed == "0" ? (
                <NavLink className={styles.customNavLink} to="/subscription/">
                  {t("SUBSCRIPTION")}
                </NavLink>
              ) : (
                ""
              )
            ) : (
              <NavLink className={styles.customNavLink} to="/subscription/">
                {t("SUBSCRIPTION")}
              </NavLink>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
}
export default HeaderSection;
